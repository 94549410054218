<script setup lang="ts">
import axios from "axios";
import { ref } from "vue";

const props = defineProps({
  box_id: Number,
});

const startDate = ref(new Date().toISOString().slice(0, 10));
const endDate = ref(new Date().toISOString().slice(0, 10));

const download = async () => {
  console.log(startDate.value);
  console.log(endDate.value);

  const result = await axios.get(
    `http://dev.peerline.net:9987/excel?box_id=${
      props.box_id
    }&start_date=${startDate.value.replace(
      /-/g,
      ""
    )}&end_date=${endDate.value.replace(/-/g, "")}`,
    { responseType: "arraybuffer" }
  );

  // console.log(data);

  const url = window.URL.createObjectURL(
    new Blob([result.data], { type: result.headers["content-type"] })
  );
  const link = document.createElement("a");
  link.href = url;
  link.download = "excel.xlsx";
  link.click();
  window.URL.revokeObjectURL(url);
};
</script>

<template>
  <div class="excel d-flex align-items-center">
    <input
      class="mr-10px form form-control"
      v-model="startDate"
      type="date"
      name=""
      id=""
    />
    <span class="mr-10px">~</span>
    <input
      class="mr-10px form form-control"
      v-model="endDate"
      type="date"
      name=""
      id=""
    />
    <button type="button" class="btn btn-primary" @click="download">
      엑셀 다운로드
    </button>
  </div>
</template>

<style lang="scss">
.excel {
  input {
    width: 150px;
  }
}
.mr-10px {
  margin-right: 10px;
}
</style>
