<script setup lang="ts">
// import Box from "@/components/Container.vue";
import Card from "@/components/Card.vue";
import NonPackage from "@/components/NonPackage.vue";
import Box from "@/components/Package.vue";
import axios from "axios";
import { onMounted, PropType, Ref, ref } from "vue";
import router from "@/router";
import store from "@/store";

const props = defineProps({
  // sensorList: Array as PropType<Sensor[]>,
  gateway_id: Number,
  sensorList: Array,
  boxList: Array as PropType<Box[]>,
});

// console.log('props');
// console.log(props.boxList);
// console.log(props.sensorList);

//센스 형태 지정
// interface Sensor {
//   area_id: string;
//   controller_id: string;
//   created_date: string;
//   event_date: string;
//   event_time: string;
//   field_id: string;
//   sensor_id: string;
//   sensor_value_type: string;
//   unit_data: number;
//   unit_id: string;
//   unit_type_id: string;
// }

//박스 형태 지정
interface Box {
  box_id: number;
  box_name: string;
  sensor_list: Array<any>;
}

let sensor_data: any = ref(props.sensorList);
let box_data: Ref<Box[] | undefined> = ref(props.boxList);

// onMounted(async () => {
//   await call_data();
// });

// const call_data = async () => {
//   if (true) {
//     const { data } = await axios.get(
//       "http://dev.peerline.net:9987/sensor_data"
//     );

//     // 배터리 제외
//     sensor_data.value = data.data;
//   }

//   if (true) {
//     const { data } = await axios.get("http://dev.peerline.net:9987/box");

//     // 배터리 제외
//     box_data.value = data.data;
//   }
// };
const create_box = async (id) => {
  store.state.create_gateway_id = id;
};
</script>

<template>
  <div>
    <div class="section-wrap" id="section-wrap">
      <div class="sensor-list">
        <Card
          v-for="sensor in sensor_data"
          :key="sensor"
          :sensor="sensor"
          :gateway_id
        />
      </div>
      <div class="box-list">
        <button
          @click="create_box(gateway_id)"
          v-if="box_data ? !box_data.length : true"
          type="button"
          class="no-box btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#createBoxModal"
        >
          포장 추가
        </button>
        <Box
          v-for="box in box_data"
          :key="box"
          :name="box.box_name"
          :id="box.box_id"
          :sensor_list="box.sensor_list"
        />
        <button
          @click="create_box(gateway_id)"
          v-if="box_data ? box_data.length : false"
          type="button"
          class="add-box btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#createBoxModal"
        >
          포장 추가
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrap {
  .section {
    // padding: 10px;
    .section-wrap {
      display: flex;
      justify-content: space-between;
      .sensor-list {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        overflow-y: auto;
        padding: 10px;
        width: 500px;
        background: #b0beca;
        height: calc(100vh - 70px);
      }
      .box-list {
        overflow-y: auto;
        padding: 10px;
        position: relative;
        width: calc(100vw - 600px);
        height: calc(100vh - 70px);
        // background: #ffc3c3;
        .no-box {
          padding: 10px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid #000;
        }
      }
    }
  }
}
</style>
