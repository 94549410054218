import { createStore } from "vuex";

export default createStore({
  state: {
    reload: 0,
    delete_box_id: 0,
    dnd_sensor_data: null,
    create_gateway_id: 0
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
