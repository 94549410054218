<template>
  <div class="non-package-wrap target" @dragover="dragOver" @drop="drop">
    <div class="title d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <div
          v-if="!toggle_boxName"
          class="mr-10px"
          @click="toggle_boxName = !toggle_boxName"
        >
          <div style="width: 100px; cursor: pointer">
            {{ name }}
          </div>
        </div>
        <!-- <div v-if="toggle_boxName" class="mr-10px" @click="toggle_boxName = !toggle_boxName"> -->
        <div v-if="toggle_boxName" class="mr-10px">
          <input
            class="form form-control"
            type="text"
            :value="name"
            style="width: 100px"
            @keypress="change_boxName"
          />
        </div>
        <div class="d-flex align-items-center">
          <Excel :box_id="props.id" />
        </div>
      </div>
      <div>
        <button
          @click="delete_box"
          type="button"
          class="delete-box btn-close"
          data-bs-toggle="modal"
          data-bs-target="#deleteBoxModal"
        ></button>
      </div>
      <!-- <i data-bs-dismiss="modal" class="mdi mdi-close-thick"></i> -->
    </div>
    <div class="content">
      <Card
        v-for="sensor in sensor_list"
        :key="sensor"
        :sensor="sensor"
        type="box"
      />
    </div>
  </div>
</template>

<script setup>
import Card from "@/components/Card.vue";
import Excel from "@/components/Box/Excel.vue";

import { onMounted, Ref, ref } from "vue";
import axios from "axios";
import router from "@/router";
import store from "@/store";

const props = defineProps({
  name: String,
  id: Number,
  sensor_list: Array,
});

const delete_box = async () => {
  store.state.delete_box_id = props.id;
};

const dragOver = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const drop = async (e) => {
  e.preventDefault();
  e.stopPropagation();

  console.log(e);
  console.log(e.target);
  console.log(e.offsetX);
  console.log(e.offsetY);

  const [unit_id, clickX, clickY] = e.dataTransfer
    .getData("text/plain")
    .split(",");

  console.log("unit_id: ", unit_id);
  console.log(clickX);
  console.log(clickY);

  await axios.post("http://dev.peerline.net:9987/position", {
    box_id: props.id,
    unit_id,
    x: e.offsetX - clickX,
    y: e.offsetY - clickY,
  });

  store.state.reload++;
};

const change_boxName = async (e) => {
  if (e.keyCode == 13) {
    await axios.put("http://dev.peerline.net:9987/box", {
      box_id: props.id,
      box_name: e.target.value,
    });

    store.state.reload++;
  }
};

// let senss
// onMounted(async () => {
//   await call_data();
// });

// const call_data = async () => {
//   if (true) {
//     const { data } = await axios.get("http://dev.peerline.net:9987/box");

//     // 배터리 제외
//     sensor_data.value = data.data;
//   }

//   if (true) {
//     const { data } = await axios.get("http://dev.peerline.net:9987/box");

//     // 배터리 제외
//     box_data.value = data.data;
//   }
// };
const toggle_boxName = ref(false);
</script>

<style lang="scss" scoped>
.non-package-wrap {
  margin: 0 0 10px 0;
  padding: 10px 10px 30px 10px;
  width: calc(100%);
  height: 500px;
  background: #fff;
  color: #000;
  .content {
    margin-top: 10px;
    position: relative;
    width: 100%;
    height: calc(100% - 25px);
    border: 1px solid #cfb4b4;
    border-radius: 10px;
  }
}
.mr-10px {
  margin-right: 10px;
}
.w-100px {
  width: 100px;
}
</style>
