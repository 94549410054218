<script setup lang="ts">
import GateSection from "@/components/Gate/GateSection.vue";
import { PropType, Ref, ref, watch } from "vue";
import axios from "axios";
import router from "@/router";
import store from "@/store";

// const props = defineProps({
//   // data: Array<Gate>,
//   data: Array as PropType<Gate[]>,
// })

// console.log('props.data: ',props.data);

// watch(() => props.data, (value: Gate[]) => {
//   // console.log('value');
//   // console.log(value);

//   gates.value = value
// })

//게이트 형태 지정
interface Gate {
  gateway_id: number;
  gateway_mac: string;
  gateway_name: string;
  sensor_data: Array<any>;
  boxlist: Array<any>;
}
//게이트 목록
const gates: Ref<Gate[]> = ref([]);
const gates_data: Ref<Gate[]> = ref([]);
const index = ref(0);

const getGateCount = (gate: Gate) => {
  let result: number = 0;

  // result = gate?.sensor_data[0]?.length
  // console.log(gate.boxlist);

  if (typeof gate.sensor_data.length == "number")
    result = gate.sensor_data.length;

  if (gate && gate.boxlist) {
    gate.boxlist.forEach((box) => {
      if (
        box &&
        box.sensor_list &&
        typeof box?.sensor_list.length == "number"
      ) {
        result = result + box.sensor_list.length;
      }
    });
  }

  return result;
};

const toggle_gatewayName = ref(false);

const change_gatewayName = async (e, gateway_id: number) => {
  if (e.keyCode == 13) {
    await axios.put("http://dev.peerline.net:9987/gateway", {
      gateway_id,
      gateway_name: e.target.value,
    });

    toggle_gatewayName.value = false;

    getData2();
  }
};

const getData = async () => {
  const { data } = await axios("http://dev.peerline.net:9987/gatewaylist");

  //  console.log('data: ', data.data);
  if (!gates.value.length) gates.value = data.data;
  gates_data.value = data.data;
};

const getData2 = async () => {
  const { data } = await axios("http://dev.peerline.net:9987/gatewaylist");

  //  console.log('data: ', data.data);
  gates.value = data.data;
  gates_data.value = data.data;
};

getData();

watch(
  () => store.state.reload,
  () => {
    getData();
  }
);
</script>

<template>
  <div class="gate">
    <div class="gate-nav" id="myTab" role="tablist">
      <div
        v-for="(gate, idx) in gates"
        :key="gate"
        class="gate-link"
        role="presentation"
      >
        <button
          @click="
            index = idx;
            console.log('index');
            console.log(index);
          "
          class="nav-link"
          :class="{ active: idx == index }"
          data-bs-toggle="tab"
          :data-bs-target="`#tab-pane${idx}`"
          type="button"
          role="tab"
          :aria-controls="`tab-pane${idx}`"
          aria-selected="true"
        >
          <div>SSGATE</div>
          <div
            v-if="!toggle_gatewayName"
            @click="toggle_gatewayName = !toggle_gatewayName"
          >
            {{ gate.gateway_name }}({{ getGateCount(gate) }})
          </div>
          <input
            class="form form-control"
            v-if="toggle_gatewayName"
            :value="gate.gateway_name"
            @keypress="change_gatewayName($event, gate.gateway_id)"
            type="text"
          />
        </button>
      </div>
    </div>
    <div>
      <div class="tab-content" id="myTabContent">
        <div
          v-for="(gate, idx) in gates_data"
          :key="gate"
          class="tab-pane fade"
          :class="{ show: idx == index, active: idx == index }"
          :id="`tab-pane${idx}`"
          role="tabpanel"
          :aria-labelledby="`tab-pane${idx}`"
          tabindex="0"
        >
          <GateSection
            :gateway_id="gate.gateway_id"
            :sensorList="gate.sensor_data"
            :boxList="gate.boxlist"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.gate {
  display: flex;
  .gate-nav {
    width: 100px;
    height: 100vh;
    background: #000;
    .gate-link {
      background: #fff;
      margin: 10px 0;
      border-radius: 20px 0 0 20px;
      display: flex;
      justify-content: center;
      button {
        text-align: center;
        &.active {
          font-weight: bold;
        }
        input {
          display: block;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
.tab-content {
  color: #fff;
}
</style>
