<script setup lang="ts">
import Data from "@/components/Data.vue";
import { ref, PropType } from "vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";


const props = defineProps({
  sensor: Array as PropType<Sensor[]>,
  type: String,
  gateway_id: Number,
});

// console.log('props.gateway_id');
// console.log(props.gateway_id);


//센서 형태 지정
interface Sensor {
  area_id: string;
  controller_id: string;
  created_date: string;
  event_date: string;
  event_time: string;
  field_id: string;
  sensor_id: string;
  sensor_value_type: string;
  unit_data: number;
  unit_id: string;
  unit_type_id: string;
}


// console.log('props.sensor');
// console.log(props.sensor);
// console.log('props.type');
// console.log(props.type);



const delete_sensor = async (unit_id: string, gateway_id: number) => {
  if (confirm("센서를 삭제하시겠습니까?")) {
    await axios.delete("http://dev.peerline.net:9987/sensor", {
      data: {
        unit_id,
        gateway_id
      }
    })
    
    store.state.reload++
  }
};

const back_sensor = async () => {
  console.log("back_sensor");

  await axios.delete("http://dev.peerline.net:9987/position", {
    data: {
      box_id: props.sensor[0].box_id,
      unit_id: props.sensor[0].sensor_id,
      x: 0,
      y: 0,
    },
  });

  store.state.reload++
};

const dragStart = (e, sensor_id) => {
  console.log(e);
  console.log(sensor_id);
  console.log(e.offsetX);
  console.log(e.offsetY);

  // e.dataTransfer.setData("text/plain", sensor_id);
  e.dataTransfer.setData("text/plain", [sensor_id, e.offsetX, e.offsetY]);
  // store.state.dnd_sensor_data = e.target;
};
// const date = new Date(new Date().getTime() + 9 * 60 * 60 * 1000)
//   .toISOString()
const gradationColor = (value: number) => {
  //중간은 하양(255,255,255) 뜨거우면 빨강(255,0,0) 차가우면 파랑 (0,0,255)
  // 최저 -10 최고 40
  let red = 170;
  let green = 170;
  let blue = 170;

  const rule = ((value - 15) * 4) / 100; // 최저 -100 최고 100

  if (rule > 0) {
    green = 170 * (1 - rule);
    blue = 170 * (1 - rule);
  }

  if (rule < 0) {
    green = 170 * (1 + rule);
    red = 170 * (1 + rule);
  }

  // if (type == "1") {
  //   const rule = ((value - 50) * 2) / 100; // 최저 -100 최고 100

  //   if (rule > 0) {
  //     green = 255 * (1 - rule);
  //     blue = 255 * (1 - rule);
  //   }

  //   if (rule < 0) {
  //     green = 255 * (1 + rule);
  //     red = 255 * (1 + rule);
  //   }
  // }

  return `rgb(${red}, ${green}, ${blue})`;
};
</script>

<template>
  <div
    class="card-wrap draggable draggable-a"
    id="card"
    draggable="true"
    @dragstart="dragStart($event, sensor[0].sensor_id)"
    :style="{
      position: `${type ? 'absolute' : 'relative'}`,
      top: `${sensor[0].y}px`,
      left: `${sensor[0].x}px`,
      background: `${gradationColor(sensor[0].unit_data * 1)}`,
    }"
  >
    <div class="card-wrap-header">
      No.{{ sensor[0].sensor_id }}
      <i v-if="!type" @click="delete_sensor(sensor[0].sensor_id, gateway_id)" class="mdi mdi-close-thick"></i>
      <i v-if="type" @click="back_sensor" class="mdi mdi-minus-box-outline"></i>
    </div>
    <div class="card-wrap-section">
      <Data
        v-for="data in sensor"
        :key="data"
        :type="data.sensor_value_type"
        :data="`${data.unit_data.toFixed(0)}`"
      />
    </div>
    <div class="card-wrap-footer">
      {{sensor[0].event_date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')}}
    </div>
  </div>
</template>


<style lang="scss" scoped>
.card-wrap {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: block;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #3b4054;
  background-clip: border-box;
  border: none;
  border-radius: 0.25rem;
  padding: 5px;
  margin: 5px;
  width: 85px;
  height: 85px;
  .card-wrap-header {
    font-size: 10px;
    font-weight: bold;
    border-bottom: 1px solid #fff;
  }
  .card-wrap-section {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // height: 100px;
    padding: 4px 0;
    font-size: 24px;
    min-width: 110px;
  }
  .card-wrap-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    i {
      cursor: pointer;
      margin-top: 1px;
    }
  }
}
</style>
