<script setup lang="ts">
import { ref } from "vue";
import router from "@/router";
import axios from "axios";
import store from "@/store";

let box_name = ref("");
let close = ref();

const create_box = async () => {
  await axios.post(
    "http://dev.peerline.net:9987/box",
    {
      box_id: 0,
      box_name: box_name.value,
      gateway_id: store.state.create_gateway_id,
    },
    {
      // headers: { "Content-Type": "application/json" },
      // withCredentials: true,
    }
  );

  store.state.create_gateway_id = 0;
  box_name.value = "";

  store.state.reload++;
  console.log(close.value);

  close.value.click();
  // await call_data();
  // await create_move_function();
};
</script>

<template>
  <div
    class="modal fade"
    id="createBoxModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            포장 이름 등록 및 생성
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <label class="form-label">포장 이름</label>
          <input class="form form-control" type="text" v-model="box_name" />
          {{ box_name }}
        </div>
        <div class="modal-footer">
          <button
            ref="close"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            닫기
          </button>
          <button type="button" class="btn btn-primary" @click="create_box">
            적용
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
