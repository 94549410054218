<script setup lang="ts">
import { ref } from "vue";
import router from "@/router";
import axios from "axios";
import store from "@/store";

let box_id = ref("");
let close = ref()

const delete_box = async () => {
  // console.log(store.statea.delete_box_id);
  
  await axios.delete("http://dev.peerline.net:9987/box", {
    data: {
      box_id: store.state.delete_box_id,
    },
  });

  store.state.delete_box_id = 0;
  store.state.reload++
  console.log(close.value);
  close.value.click()
};
</script>

<template>
  <div
    class="modal fade"
    id="deleteBoxModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            포장 삭제하시겠습니까?
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-footer">
          <button
          ref="close"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            아니오
          </button>
          <button type="button" class="btn btn-primary" @click="delete_box">
            예
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped></style>
